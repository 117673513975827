/* eslint-disable react/prop-types */
import { useCallback, useEffect, useMemo, useState } from 'react';

// material-ui
import { Divider, Box, Card, Grid, CardContent, Button, useMediaQuery, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import MainCard from '../../organisms/mainCard/MainCard';
import MultiTable from '../multiTable/multiTable';
import { SubmitButton } from 'components/atoms/button/button';
import { CustomTextField, FormikAutoComplete, NestedCustomTextField } from 'utils/textfield';
import {
  formAllValues,
  validationSchema,
  // tableColumns,
  VisibleColumn,
  genderData,
  StatusCell
} from 'constant/investorValidation';
import {
  GetInvestorData,
  SaveInvestor,
  EditInvestor,
  DeleteOneInvestor,
  GetEditOneInvestor,
  GetIfa,
  GetIFASearch
} from 'hooks/investor/investor';
import IconTabs from 'components/organisms/investorTabs';
import Loader from 'components/atoms/loader/Loader';
import axios from 'utils/axios';

// third-party
import { enqueueSnackbar } from 'notistack';
import { Formik } from 'formik';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { toInteger } from 'lodash';

// assets
import { Add, FilterSearch } from 'iconsax-react';
import { minWidth } from '@mui/system';
import { Stack } from 'immutable';
import LoadingButton from 'helpers/@extended/LoadingButton';
import { GetIpoOrdersData } from 'hooks/ipoOrders/ipoOrders';

function IpoOrder() {
  // Main data states
  const [investorData, setInvestorData] = useState([]);
  const [ifaData, setIfaData] = useState([]);
  // const [loading, setLoading] = useState(true);

  // Edit Logic State
  const [isEditing, setIsEditing] = useState(false);
  const [isInvestorActive, setInvestorActive] = useState();

  // Toggle Table and Form Visibility
  const [showTable, setShowTable] = useState(false); // State to toggle visibility of the table form
  // Loader

  // Selection states
  const [selectedGender, setSelectedGender] = useState(null);

  const [selectedDeclaration, setSelectedDeclaration] = useState({
    isPoliticallyExposed: false,
    isRelativeToPoliticallyExposed: false,
    isResidentOutsideIndia: false
  });

  // Nominee
  const [nomineeData, setNomineeData] = useState([]);

  // Tabs Error
  const [errorObject, setErrorObject] = useState({
    personalInfoError: false,
    addressDetailsError: false,
    professionalDetailsError: false,
    nomineeError: false,
    declarationError: false
  });
  const [personalInfoError, setPersonalInfoError] = useState(false);
  const [addressDetailsError, setAddressDetailsError] = useState(false);
  const [professionalDetailsError, setProfessionalDetailsError] = useState(false);
  const [nomineeError, setNomineeError] = useState(false);
  const [declarationError, setDeclarationError] = useState(false);
  const [occupations, setOccupations] = useState([]);
  const [annualIncome, setAnnualIncome] = useState([]);
  const [sourceOfIncome, setSourceOfIncome] = useState([]);
  const [relationshipData, setRelationshipData] = useState([]);

  // Form State
  const [formValues, setFormValues] = useState(formAllValues);
  // Theme
  const theme = useTheme();
  const mdUp = theme.breakpoints.up('md');
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Actions
  const [investorSearching, setInvestorSearching] = useState(false);
  const [investorSubmitting, setInvestorSubmitting] = useState(false);
  const [investorDeleting, setInvestorDeleting] = useState(false);

  const checkVerificationStatus = (row) => {
    if (Number(row.is_ckyc_verified) === 1 || Number(row.is_digilocker_verified) === 1) {
      return 'Yes';
    }
    return 'No';
  };
  // Sets form values for editing
  const setEditing = (value) => {
    console.log('value of setOccupations', value?.relationship);
    setAnnualIncome(value?.annual_income ?? []);
    setSourceOfIncome(value?.income_source ?? []);
    setOccupations(value?.occuption ?? []);
    setRelationshipData(value?.relationship ?? []);
    setFormValues(value);
    handleIsInvestorActive(value.investor.is_active);
    setSelectedGender(value.investor.gender);
    setSelectedDeclaration({
      isPoliticallyExposed: Boolean(value.declaration.is_pep),
      isRelativeToPoliticallyExposed: Boolean(value.declaration.is_rpep),
      isResidentOutsideIndia: Boolean(value.declaration.is_foreign_tax_resident)
    });
  };
  const setActiveEditing = () => {
    setIsEditing(true);
  };
  const setActiveClose = () => {
    setIsEditing(false);
  };
  const handleIsInvestorActive = (initialValue) => {
    setInvestorActive(initialValue);
  };

  // Toggle Table and Form Visibility
  const changeTableVisibility = () => {
    setShowTable(!showTable);
  };

  // Search one item state
  const setSearchData = (investor) => {
    setInvestorData(investor);
  };

  const tableColumns = [
    {
      Header: 'Date',
      accessor: 'created_on',
      minWidth: 150
    },
    {
      Header: 'Order ID',
      accessor: 'pre_ipo_investment_id'
    },
    {
      Header: 'IFA Name',
      accessor: 'ifa_name',
      minWidth: 150
    },
    {
      Header: 'IFA Id',
      accessor: 'ifa_id',
    },
    {
      Header: 'Share Name',
      accessor: 'pre_ipo_share_name'
    },
    {
      Header: 'Share Qty',
      accessor: 'qty_booked'
    },
    {
      Header: 'Share Landing price',
      accessor: 'market_price'
    },
    {
      Header: 'Investor Price',
      accessor: 'investor_price'
    },
    {
      Header: 'Investor Name',
      accessor: 'investor_name',
      minWidth: 250,
      Cell: ({ value, row }) => {
        // State to manage loading for each row
        const [loadingInvestor, setLoadingInvestor] = useState(false);

        const handleInvestorClick = async () => {
          try {
            setLoadingInvestor(true);
            await GetEditOneInvestor(setEditing, row.original.investor_id);
            setActiveEditing();
            changeTableVisibility();
          } catch (err) {
            console.log(err);
          } finally {
            setLoadingInvestor(false);
          }
        };
        return (
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Link component="button" variant="body2" underline="always" sx={{ fontSize: '0.80rem' }} onClick={handleInvestorClick}>
              {value}
            </Link>
            <LoadingButton loading={loadingInvestor} size="medium" color="secondary" loadingPosition="center" />
          </Box>
        );
      }
    },
    {
      Header: 'Investor ID',
      accessor: 'investor_id'
    },
    {
      Header: 'Investment amount',
      accessor: 'total_payable'
    },
    {
      Header: 'Gross margin',
      accessor: 'gross_margin',
    },
  ];

  // const tableColumns = [
  //   {
  //     Header: 'Order ID',
  //     accessor: 'master_id'
  //   },
  //   {
  //     Header: 'Investor ID',
  //     accessor: 'investor_id'
  //   },
  //   {
  //     Header: 'Folio ID',
  //     accessor: 'folio_code'
  //   },
  //   {
  //     Header: 'Investor Name',
  //     accessor: 'investor_name',
  //     minWidth: 250,
  //     Cell: ({ value, row }) => {
  //       // State to manage loading for each row
  //       const [loadingInvestor, setLoadingInvestor] = useState(false);

  //       const handleInvestorClick = async () => {
  //         try {
  //           setLoadingInvestor(true);
  //           await GetEditOneInvestor(setEditing, row.original.investor_id);
  //           setActiveEditing();
  //           changeTableVisibility();
  //         } catch (err) {
  //           console.log(err);
  //         } finally {
  //           setLoadingInvestor(false);
  //         }
  //       };
  //       return (
  //         <Box display="flex" justifyContent="flex-start" alignItems="center">
  //           <Link component="button" variant="body2" underline="always" sx={{ fontSize: '0.80rem' }} onClick={handleInvestorClick}>
  //             {value}
  //           </Link>
  //           <LoadingButton loading={loadingInvestor} size="medium" color="secondary" loadingPosition="center" />
  //         </Box>
  //       );
  //     }
  //   },
  //   {
  //     Header: 'IFA',
  //     accessor: 'ifa_name',
  //     minWidth: 150
  //   },

  //   {
  //     Header: 'Pan Number',
  //     accessor: 'pan_no'
  //   },
  //   // {
  //   //   Header: 'Kyc Status',
  //   //   accessor: 'is_ckyc_verified',
  //   //   customCell: ({ value }) => {
  //   //     switch (value) {
  //   //       case 0:
  //   //         return 'No';
  //   //       case 1:
  //   //         return 'Yes';
  //   //       default:
  //   //         return 'No';
  //   //     }
  //   //   }
  //   // },
  //   {
  //     Header: 'Kyc Status',
  //     accessor: (row) => checkVerificationStatus(row),
  //     id: 'kyc_status', // Provide an id for this column
  //     Cell: ({ value }) => value // Render the computed value,
  //     // customCell: StatusCell
  //     // Cell: ({ value }) => <KycStatusCell value={value} />
  //   },
  //   {
  //     Header: 'Email',
  //     accessor: 'email_id'
  //   },
  //   {
  //     Header: 'Mobile Number',
  //     accessor: 'mobile_no'
  //   },
  //   {
  //     Header: 'Reg. date',
  //     accessor: 'created_on'
  //   },
  //   {
  //     Header: 'Status',
  //     accessor: 'is_active',
  //     customCell: StatusCell
  //   }
  // ];

  // Empty Form Fields
  
  const clearFormValues = () => {
    setFormValues(formAllValues);
    setSelectedGender();
    setSelectedDeclaration({
      isPoliticallyExposed: false,
      isRelativeToPoliticallyExposed: false,
      isResidentOutsideIndia: false
    });
    setPersonalInfoError(false);
    setAddressDetailsError(false);
    setProfessionalDetailsError(false);
    setNomineeError(false);
    setDeclarationError(false);
  };

  // Gender Validation
  const genderValidate = (value) => {
    if (typeof value === 'string') {
      genderData.find((el) => {
        if (el.gender === value) {
          return el.id;
        }
      });
    } else {
      return value;
    }
  };
  // Nominee
  const handleNewNominee = (value) => {
    console.log(value);
    setNomineeData((prev) => {
      return [...prev, value];
    });
  };
  // Declaration
  const handleDeclarationClick = (value) => {
    if (value === 'PoliticallyExposed') {
      setSelectedDeclaration({ ...selectedDeclaration, isPoliticallyExposed: !selectedDeclaration.isPoliticallyExposed });
    } else if (value === 'RelativeToPoliticallyExposed') {
      setSelectedDeclaration({
        ...selectedDeclaration,
        isRelativeToPoliticallyExposed: !selectedDeclaration.isRelativeToPoliticallyExposed
      });
    } else if (value === 'ResidentOutsideIndia') {
      setSelectedDeclaration({ ...selectedDeclaration, isResidentOutsideIndia: !selectedDeclaration.isResidentOutsideIndia });
    }
  };

  // Custom fields/ Table Columns
  const columns = useMemo(() => tableColumns, []);

  // Query for fetching IFA data
  const {
    isPending: ifaPending,
    error: ifaError,
    refetch: IfaTableDataRefetch
  } = useQuery({
    queryKey: ['ifaTableData'],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryFn: () => {
      const payload = {
        method_name: 'getall'
      };
      return GetIfa(payload);
    },
    onSuccess: (data) => {
      setIfaData(data);
    }
  });

  // Query for fetching investor data
  const {
    isFetching,
    isPending,
    error,
    refetch: IpoOrdersTableDataRefetch
  } = useQuery({
    queryKey: ['ipoOrdersTableData'],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryFn: () => {
      return GetIpoOrdersData();
    },
    onSuccess: (data) => {
      setInvestorData(data);
    }
  });


  if (isPending || ifaPending) return <Loader />;

  return (
    <>
      {showTable && (
        <Formik
          validateOnBlur={false}
          // validateOnChange={false}
          // validate={validate}
          enableReinitialize={true}
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const userID = localStorage.getItem('userID');
            if (isEditing === false) {
              const payload = {
                ...values,
                user_id: toInteger(userID),
                method_name: 'add',
                investor: {
                  ...values.investor,
                  gender_id: genderValidate(selectedGender),
                  is_foreign_tax_resident: toInteger(selectedDeclaration.isResidentOutsideIndia),
                  is_rpep: toInteger(selectedDeclaration.isRelativeToPoliticallyExposed),
                  is_pep: toInteger(selectedDeclaration.isPoliticallyExposed)
                }
              };
              try {
                setInvestorSubmitting(true);

                await SaveInvestor(payload);

                changeTableVisibility();
                IpoOrdersTableDataRefetch();
                clearFormValues();
              } catch (err) {
                console.log(err);
              } finally {
                setInvestorSubmitting(false);
              }
            }
            if (isEditing === true) {
              const { port_folio, investor_bank, ...newData } = values;
              try {
                setInvestorSubmitting(true);
                const payload = {
                  ...newData,
                  user_id: toInteger(userID),
                  investor_id: values.investor.investor_id,
                  method_name: 'update',
                  investor: {
                    ...values.investor,
                    is_active: toInteger(isInvestorActive),
                    gender_id: genderValidate(selectedGender),
                    is_foreign_tax_resident: toInteger(selectedDeclaration.isResidentOutsideIndia),
                    is_rpep: toInteger(selectedDeclaration.isRelativeToPoliticallyExposed),
                    is_pep: toInteger(selectedDeclaration.isPoliticallyExposed)
                  }
                };

                await EditInvestor(payload);

                changeTableVisibility();
                IpoOrdersTableDataRefetch();
                clearFormValues();
                setActiveClose();
              } catch (err) {
                console.log(err);
              } finally {
                setInvestorSubmitting(false);
              }
            }
            // setErrorObject(errorObject);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
            dirty,
            resetForm,
            isSubmitting
          }) => (
            <Box
              component="form"
              onSubmit={(event) => {
                handleSubmit();
                event.preventDefault();
              }}
              sx={{ width: '100%' }}
            >
              <Card
                sx={{
                  position: 'relative',
                  border: '1px solid',
                  borderRadius: 1.5,
                  borderColor: theme.palette.divider,
                  overflow: 'visible'
                }}
              >
                <SubmitButton
                  title="Investor Entry"
                  loading={investorSubmitting}
                  changeTableVisibility={changeTableVisibility}
                  clearFormValues={clearFormValues}
                  isEditing={isEditing}
                  formValues={formValues?.investor}
                  setActiveClose={setActiveClose}
                  setIsActive={handleIsInvestorActive}
                  isActive={isInvestorActive}
                  isValid={isValid}
                  dirty={dirty}
                />

                <Divider />

                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={6}>
                      <NestedCustomTextField
                        disabled={values.investor.is_ckyc_verified || values.investor.is_digilocker_verified}
                        label="Investor Name"
                        valueName="investor.investor_name"
                        placeholder="Please enter Investor Name"
                        values={values.investor.investor_name}
                        type="text"
                        regType="string"
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <NestedCustomTextField
                        disabled={values.investor.is_ckyc_verified || values.investor.is_digilocker_verified}
                        label="Mobile Number"
                        valueName="investor.mobile_no"
                        placeholder="Please enter Mobile Number"
                        values={values.investor.mobile_no}
                        type="string"
                        regType="number"
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                    {isEditing && (
                      <Grid item md={4} xs={6}>
                        <NestedCustomTextField
                          disabled={true}
                          label="Order ID"
                          valueName="investor.investor_code"
                          values={values.investor.order_id}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>

                <Grid item xs={12} lg={6}>
                  <IconTabs
                    occupations={occupations}
                    annualIncome={annualIncome}
                    sourceOfIncome={sourceOfIncome}
                    relationshipData={relationshipData}
                    values={values}
                    validationSchema={validationSchema}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isEditing={isEditing}
                    errors={errors}
                    touched={touched}
                    setEditing={setEditing}
                    setFieldValue={setFieldValue}
                    selectedGender={selectedGender}
                    setSelectedGender={setSelectedGender}
                    selectedDeclaration={selectedDeclaration}
                    handleDeclarationClick={handleDeclarationClick}
                    nomineeData={nomineeData}
                    handleNewNominee={handleNewNominee}
                    // errorObject={errorObject}
                    // handleTabError={handleTabError}
                    personalInfoError={personalInfoError}
                    setPersonalInfoError={setPersonalInfoError}
                    addressDetailsError={addressDetailsError}
                    setAddressDetailsError={setAddressDetailsError}
                    professionalDetailsError={professionalDetailsError}
                    setProfessionalDetailsError={setProfessionalDetailsError}
                    nomineeError={nomineeError}
                    setNomineeError={setNomineeError}
                    declarationError={declarationError}
                    setDeclarationError={setDeclarationError}
                    isValid={isValid}
                    dirty={dirty}
                  />
                </Grid>
              </Card>
            </Box>
          )}
        </Formik>
      )}
      {!showTable && (
        <MainCard
          title="Pre-IPO Order Summary"
          changeTableVisibility={changeTableVisibility}
          noAddButton
          setActiveAdding={setActiveClose}
          border
          contentSX={{ p: 2 }}
          sx={{ height: '100%', boxShadow: 1 }}
        >
          <Formik
            initialValues={{
              search: '',
              ifa_id: 0
            }}
            onSubmit={async (values, { resetForm }) => {
              try {
                setInvestorSearching(true);
                const payload = {
                  method_name: 'getinvestorsearch',
                  ...values
                };
                const searchResult = await GetIFASearch(payload);
                if (searchResult) {
                  setSearchData(searchResult);
                }
              } catch (err) {
                console.log(err);
              } finally {
                setInvestorSearching(false);
              }
            }}
          >
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, resetForm }) => (
              <Box
                component="form"
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
                sx={{ width: '100%' }}
              >
                <CardContent sx={{ paddingLeft: '16px !important', paddingRight: matchDownSM ? '0px !important' : '24px !important' }}>
                  <Grid container spacing={2}>
                    <Grid item md={2.5} sm={3} xs={4} style={{ paddingLeft: 0, paddingTop: 0 }}>
                      <FormikAutoComplete
                        options={ifaData}
                        defaultValue={values.ifa_id}
                        setFieldValue={setFieldValue}
                        formName="ifa_id"
                        optionName="item_value"
                        label="Select IFA"
                      />
                    </Grid>

                    <Grid item md={2.5} sm={3} xs={4} style={{ paddingTop: 0 }}>
                      <CustomTextField
                        label="Search"
                        name="search"
                        values={values}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0
                          }
                        }}
                      />
                    </Grid>

                    <Grid item md={1.5} sm={3} xs={4} style={{ paddingTop: 0 }}>
                      <LoadingButton
                        fullWidth
                        loading={investorSearching}
                        loadingPosition="center"
                        variant="contained"
                        color="success"
                        type="submit"
                        startIcon={<FilterSearch />}
                        sx={{
                          justifySelf: 'center',
                          width: !mdUp ? 'auto' : '100%',
                          borderRadius: 0.6 // Set width to 'auto' when screen size is medium or larger, otherwise '100%'
                        }}
                      >
                        Search
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
            )}
          </Formik>

          {/* ------------- */}
          <MultiTable
            columns={columns}
            data={investorData}
            formValues={[]}
            formValueFields={[]}
            validationSchema={{}}
            changeTableVisibility={changeTableVisibility}
            setEditing={setEditing}
            getOneItem={() => {}}
            deleteOneItem={DeleteOneInvestor}
            deletingItem={investorDeleting}
            setDeletingItem={setInvestorDeleting}
            getEditData={GetEditOneInvestor}
            getEditReqField={'investor_id'}
            setSearchData={setSearchData}
            tableDataRefetch={IpoOrdersTableDataRefetch}
            setActiveEditing={setActiveEditing}
            VisibleColumn={VisibleColumn}
            isFetching={isFetching}
          />
        </MainCard>
      )}
    </>
  );
}

export default IpoOrder;
