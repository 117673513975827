import { useRoutes } from 'react-router-dom';

// project-imports
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { useSelector } from 'react-redux';
import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'helpers/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Issuer from '../issuer/issuer';
import FixDeposit from '../fixedDeposit/fixedDeposit';
import Investor from '../investor/investor';
import Investment from '../transaction/investment';
import UserList from '../userList/userList';
import Role from '../userList/role';
import Dashboard from '../dashboard/dashboard';
import FDReport from '../reports/fdReport';
import IssuerReport from '../reports/issuerReport';
import FAQ from '../faq/faq';
import FdCategory from '../fdCategory/fdCategory';
import Commission from '../commission/Commission';
import IpoOrder from '../ipoOrder/ipoOrder';

// Product
const Product = Loadable(lazy(() => import('components/pages/productType/productType')));
// User
const UserProfile = Loadable(lazy(() => import('components/pages/userProfile/user')));
const UserTabPersonal = Loadable(lazy(() => import('../../organisms/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('../../organisms/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('../../organisms/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('../../organisms/user/TabSettings')));
const Error404 = Loadable(lazy(() => import('../error/404')));

// ==============================|| ROUTES RENDER ||============================== //
const generateRoutes = (routesArray) => {
  return routesArray.map((route) => {
    const { title, url, children } = route;
    const elementMapping = {
      Dashboard: <Dashboard />,
      Investor: <Investor />,
      Issuer: <Issuer />,
      "Ipo Orders": <IpoOrder />,
      'FD Category': <FdCategory />,
      Product: <Product />,
      'Fixed Deposit': <FixDeposit />,
      Investment: <Investment />,
      'FD Report': <FDReport />,
      'Issuer Report': <IssuerReport />,
      Role: <Role />,
      Users: <UserList />,
      FAQ: <FAQ />,
      Commission: <Commission />
    };

    const newRoute = {
      path: url ? url.replace('/', '') : '',
      element: elementMapping[title]
    };

    if (children && children.length > 0) {
      newRoute.children = generateRoutes(children);
    }

    return newRoute;
  });
};

export default function ThemeRoutes() {
  const { menuItem } = useSelector((state) => state.menu);
  console.log(menuItem);
  // return useRoutes([LoginRoutes, MainRoutes]);
  return useRoutes([
    LoginRoutes,
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            ...(menuItem[0]?.children?.length > 0 ? generateRoutes(menuItem[0]?.children) : []),
            {
              path: 'user',
              element: <UserProfile />,
              children: [
                {
                  path: 'personal',
                  element: <UserTabPersonal />
                },
                {
                  path: 'payment',
                  element: <UserTabPayment />
                },
                {
                  path: 'password',
                  element: <UserTabPassword />
                },
                {
                  path: 'settings',
                  element: <UserTabSettings />
                }
              ]
            }
            // Additional static routes can go here if needed
          ]
        }
      ]
    },
    // Wildcard route to catch undefined routes
    {
      path: '*',
      element: <Error404 />
    }
  ]);
}
